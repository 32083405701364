import { React, useState } from 'react';
import './Inicio.css';
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import Imagen0 from '../../img/Slider/Imagen0.png';
import Imagen1 from '../../img/Slider/Imagen1.png';
import Imagen2 from '../../img/Slider/Imagen2.png';
import Imagen3 from '../../img/Slider/Imagen3.png';

const Inicio = ()=>{

    const [servicio, setServicio] = useState(0);

    return(
        <>
            <Slider/>
            <div className='section'>
                <h1>¿Quiénes somos?</h1>
                <h2>Nuestra prioridad es brindar soluciones integrales en logística y comercio internacional.</h2>
                <div className='desc'>
                    Somos una empresa especializada en la gestión de importaciones, exportaciones y logística integral. Ofrecemos servicios que abarcan desde la gestión de carga y aduanas hasta la búsqueda de proveedores y trámites de comercio exterior, asegurando eficiencia y cumplimiento en cada operación.
                </div>
                <div className='cuadros'>
                    <div>
                        <div className='divImg'>
                            <img src={Imagen1}/>
                        </div>
                        <h3>Trayectoria</h3>
                        <div>
                            Con años de experiencia en logística y comercio internacional, en JHAVAC hemos consolidado una reputación basada en la eficiencia y confianza, siendo aliados estratégicos para nuestros clientes en la gestión integral de sus operaciones.
                        </div>
                    </div>
                    <div>
                        <div className='divImg'>
                            <img src={Imagen2}/>
                        </div>
                        <h3>Personal Capacitado</h3>
                        <div>
                            Nuestro equipo de profesionales está en constante formación, garantizando que contamos con los conocimientos y habilidades más avanzados para ofrecer soluciones logísticas y de comercio exterior que superen las expectativas de nuestros clientes.
                        </div>
                    </div>
                    <div>
                        <div className='divImg'>
                            <img src={Imagen3}/>
                        </div>
                        <h3>Verdaderos Expertos</h3>
                        <div>
                            En JHAVAC, seguimos los más altos estándares internacionales en cada uno de nuestros servicios. Nos especializamos en la optimización de la cadena de suministro y en la gestión de trámites aduanales con la máxima precisión y eficacia.
                        </div>
                    </div>
                    <div>
                        <div className='divImg'>
                            <img src={Imagen3}/>
                        </div>
                        <h3>Tecnología de Vanguardia</h3>
                        <div>
                            Utilizamos tecnología de punta para gestionar y optimizar cada aspecto de la logística y el comercio internacional. Combinamos innovación con experiencia para ofrecer a nuestros clientes soluciones eficientes y seguras, adaptadas a sus necesidades.
                        </div>
                    </div>
                </div>
            </div>
            <div className='section'>
                <h1>¿Qué estás buscando?</h1>
                <div className='botones'>
                    <button className={servicio === 0 ? 'sel' : ''} onClick={()=>{
                        setServicio(0);
                    }}>Logística Integral</button>
                    <button className={servicio === 1 ? 'sel' : ''} onClick={()=>{
                        setServicio(1);
                    }}>Agente de Compras Internacionales</button>
                    <button className={servicio === 2 ? 'sel' : ''} onClick={()=>{
                        setServicio(2);
                    }}>Gestión de Trámites de Comercio Exterior</button>
                </div>
                {servicio === 0 &&
                    <div className='descServicio'>
                        <div>
                            <img src={Imagen1}/>
                        </div>
                        <div>
                            <h4>Logística Integral</h4>
                            <div>
                                En JHAVAC, ofrecemos un servicio de logística integral que abarca desde el manejo de carga hasta la distribución final. Nos encargamos de coordinar el transporte marítimo, terrestre y aéreo, así como de la gestión aduanal, asegurando que tus mercancías lleguen a tiempo y en perfectas condiciones.
                            </div>
                        </div>
                    </div>
                }
                {servicio === 1 &&
                    <div className='descServicio'>
                        <div>
                            <img src={Imagen2}/>
                        </div>
                        <div>
                            <h4>Agente de Compras Internacionales</h4>
                            <div>
                                Nuestro equipo en JHAVAC se especializa en la gestión de compras internacionales. Nos encargamos de buscar los mejores proveedores, realizar trámites de compra y venta, y analizar proyectos, garantizando siempre la mejor calidad y eficiencia en cada transacción.
                                </div>
                        </div>
                    </div>
                }
                {servicio === 2 &&
                    <div className='descServicio'>
                        <div>
                            <img src={Imagen3}/>
                        </div>
                        <div>
                            <h4>Gestión de Trámites de Comercio Exterior</h4>
                            <div>
                                En JHAVAC, facilitamos todos los trámites necesarios para el comercio exterior. Nos encargamos de gestionar NOM’s, certificados de origen, clasificación arancelaria y avisos de funcionamiento, asegurando el cumplimiento normativo y la eficiencia operativa.
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className='section'>
                <h2>¡Contáctanos!</h2>
                <form className='formContacto'>
                    <div>Nombre Completo <i>(Obligatorio)</i></div>
                    <div>
                        <label>
                            <span>Nombre</span>
                            <input type='text'/>
                        </label>
                        <label>
                            <span>Apellidos</span>
                            <input type='text'/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>Teléfono</span>
                            <input type='text'/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>Correo <i>(Obligatorio)</i></span>
                            <input type='text'/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>Asunto <i>(Obligatorio)</i></span>
                            <input type='text'/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>Mensaje <i>(Obligatorio)</i></span>
                            <textarea></textarea>
                        </label>
                    </div>
                    <div className='boton'>
                        <button className='btn'>ENVIAR</button>
                    </div>
                </form>
            </div>
        </>
        
    )
}

const Slider = ()=>{
    return (
        <Carousel showArrows={true} showStatus={false} swipeable={true}>
            <Link>
                <img src={Imagen0} />
            </Link>
            <Link>
                <img src={Imagen1} />
            </Link>
            <Link>
                <img src={Imagen2} />
            </Link>
            <Link>
                <img src={Imagen3} />
            </Link>
        </Carousel>
    )
}

export default Inicio;