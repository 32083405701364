import { React, useState } from 'react';
import './Contacto.css';
import { Link } from 'react-router-dom';

import Imagen0 from '../../img/Slider/Imagen0.png';
import Imagen1 from '../../img/Slider/Imagen1.png';
import Imagen2 from '../../img/Slider/Imagen2.png';
import Imagen3 from '../../img/Slider/Imagen3.png';

const Contacto = ()=>{

    const [servicio, setServicio] = useState(0);

    return(
        <>
            <div className='section'>
                <h2>¡Contáctanos!</h2>
                <form className='formContacto'>
                    <div>Nombre Completo <i>(Obligatorio)</i></div>
                    <div>
                        <label>
                            <span>Nombre</span>
                            <input type='text'/>
                        </label>
                        <label>
                            <span>Apellidos</span>
                            <input type='text'/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>Teléfono</span>
                            <input type='text'/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>Correo <i>(Obligatorio)</i></span>
                            <input type='text'/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>Asunto <i>(Obligatorio)</i></span>
                            <input type='text'/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>Mensaje <i>(Obligatorio)</i></span>
                            <textarea></textarea>
                        </label>
                    </div>
                    <div className='boton'>
                        <button className='btn'>ENVIAR</button>
                    </div>
                </form>
            </div>
        </>
        
    )
}

export default Contacto;