import { React, useState } from 'react';
import './Servicios.css';
import { Link } from 'react-router-dom';

import Imagen0 from '../../img/Slider/Imagen0.png';
import Imagen1 from '../../img/Slider/Imagen1.png';
import Imagen2 from '../../img/Slider/Imagen2.png';
import Imagen3 from '../../img/Slider/Imagen3.png';

const Servicios = ()=>{

    const [servicio, setServicio] = useState(0);

    return(
        <>
            <div className='section servicios'>
                <div className='descServicio'>
                    <div>
                        <img src={Imagen1}/>
                    </div>
                    <div>
                        <h4>Agentes de Protección Ejecutiva</h4>
                        <div>
                            Nuestros agentes altamente capacitados brindan el mejor servicio de Protección Ejecutiva.
                            Con experiencia, adiestramiento y disciplina, su objetivo principal es asegurar el bienestar del protegido.
                        </div>
                    </div>
                </div>
                <div className='descServicio'>
                    <div>
                        <img src={Imagen2}/>
                    </div>
                    <div>
                        <h4>Custodia de Transporte de Carga</h4>
                        <div>
                            Ante los riesgos actuales que enfrenta el transporte, hemos desarrollado un servicio especializado.
                            Nuestros custodios están capacitados y cuentan con el respaldo especial de Jhavac para asegurar tu cargamento desde su salida hasta su destino.
                        </div>
                    </div>
                </div>
                <div className='descServicio'>
                    <div>
                        <img src={Imagen3}/>
                    </div>
                    <div>
                        <h4>Guardias de Seguridad Intramuros</h4>
                        <div>
                            Nuestros guardias de seguridad, disponibles las 24 horas del día, los 365 días del año, están preparados para vigilar,
                            proteger y salvaguardar tanto a las personas como los bienes que les son asignados.
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export default Servicios;