import React from 'react';
import { BrowserRouter, Route, Routes, Outlet, NavLink } from 'react-router-dom';
import './App.css';

import NavBar from './Components/NavBar/NavBar';
import Inicio from './Components/Inicio/Inicio';
import Footer from './Components/Footer/Footer';
import Servicios from './Components/Servicios/Servicios';
import Nosotros from './Components/Nosotros/Nosotros';
import Acerca from './Components/Acerca/Acerca';
import Contacto from './Components/Contacto/Contacto';

function App() {
  return (
    <BrowserRouter basename='/'>
      <div className="contenedor">
        <NavBar/>
        <Routes>
          <Route path='/' element={<Inicio/> }></Route>
          <Route path='/servicios' element={<Servicios/> }></Route>
          <Route path='/nosotros' element={<Nosotros/> }></Route>
          <Route path='/acerca' element={<Acerca/> }></Route>
          <Route path='/contacto' element={<Contacto/> }></Route>
        </Routes>
      </div>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
