import { React } from 'react';
import { NavLink, Link } from 'react-router-dom';
import './NavBar.css';

import Logo from '../../img/Logo.png';

const NavBar = ()=>{
    return(
        <nav>
            <div>
                <Link to='/'>
                    <img className='log' src={Logo}/>
                </Link>
            </div>
            <div className='links'>
                <NavLink to='/servicios'>Servicios</NavLink>
                <NavLink to='/nosotros'>Nosotros</NavLink>
                <NavLink to='/acerca'>Misión y Visión</NavLink>
                <NavLink to='/contacto'>Contáctanos</NavLink>
            </div>
        </nav>
    )
}

export default NavBar;