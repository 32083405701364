import { React, useState } from 'react';
import './Acerca.css';
import { Link } from 'react-router-dom';

import Imagen0 from '../../img/Slider/Imagen0.png';
import Imagen1 from '../../img/Slider/Imagen1.png';
import Imagen2 from '../../img/Slider/Imagen2.png';
import Imagen3 from '../../img/Slider/Imagen3.png';

const Acerca = ()=>{

    const [servicio, setServicio] = useState(0);

    return(
        <>
            <div className='section servicios acerca'>
                <div className='descServicio'>
                    <div>
                        <img src={Imagen1}/>
                    </div>
                    <div>
                        <h4>Misión</h4>
                        <div>
                            En Soluciones Empresariales Jhavac, nuestra misión es proporcionar servicios profesionales y técnicos de excelencia, combinando innovación y eficiencia para satisfacer las necesidades de nuestros clientes tanto a nivel nacional como internacional. Nos dedicamos a la comercialización de servicios y productos de alta calidad, apoyados en un sólido conocimiento técnico y un compromiso inquebrantable con la satisfacción del cliente. Nuestro objetivo es impulsar el crecimiento y éxito de nuestros clientes mediante soluciones integrales que optimicen sus operaciones y fortalezcan su competitividad en el mercado global.
                        </div>
                    </div>
                </div>
                <div className='descServicio'>
                    <div>
                        <img src={Imagen2}/>
                    </div>
                    <div>
                        <h4>Visión</h4>
                        <div>
                            Nuestra visión en Soluciones Empresariales Jhavac es ser líderes reconocidos en el ámbito de la comercialización de servicios profesionales y técnicos, así como en la producción de sistemas innovadores. Aspiramos a expandir nuestra presencia global, estableciendo relaciones duraderas y de confianza con clientes que deseen expandir y posicionar su negocio nivel globlal. Buscamos ser la primera opción para empresas que valoran la calidad, la eficiencia y la innovación, asegurando un impacto positivo y duradero en sus operaciones y estrategias de negocio.
                        </div>
                    </div>
                </div>
                <div className='descServicio'>
                    <div>
                        <img src={Imagen3}/>
                    </div>
                    <div>
                        <h4>Valores</h4>
                        <div>
                            <ul>
                                <li>
                                    <i>Excelencia:</i>
                                    <span>Nos comprometemos a ofrecer productos y servicios de la más alta calidad, superando constantemente las expectativas de nuestros clientes.</span>
                                </li>
                                <li>
                                    <i>Innovación:</i>
                                    <span>
                                        Fomentamos una cultura de creatividad y mejora continua, desarrollando soluciones técnicas y profesionales que se adaptan a un entorno empresarial en constante cambio.
                                    </span>
                                </li>
                                <li>
                                    <i>Integridad:</i>
                                    <span>
                                        Actuamos con transparencia y ética en todas nuestras operaciones, estableciendo relaciones de confianza y respeto con nuestros clientes y socios.
                                    </span>
                                </li>
                                <li>
                                    <i>Compromiso con el Cliente:</i>
                                    <span>
                                        Ponemos las necesidades de nuestros clientes en el centro de nuestras operaciones, ofreciendo un servicio personalizado y soluciones a medida que garantizan su éxito.
                                    </span>
                                </li>
                                <li>
                                    <i>Globalidad:</i>
                                    <span>
                                        Nos orientamos hacia el mercado global, entendiendo y respondiendo a las demandas y oportunidades internacionales, y facilitando la importación y exportación de productos y servicios de manera eficiente.
                                    </span>
                                </li>
                                <li>
                                    <i>Profesionalismo:</i>
                                    <span>
                                        Mantenemos los más altos estándares de profesionalismo en todas nuestras interacciones y entregables, asegurando que cada proyecto se maneje con la máxima competencia y responsabilidad.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export default Acerca;