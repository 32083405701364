import { React, useState } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

import Facebook from '../../img/Facebook.png';
import Instagram from '../../img/Instagram.png';
import Twitter from '../../img/Twitter.png';
import Youtube from '../../img/Youtube.png';

const Footer = ()=>{

    return(
        <>
            <div className='section'>
                <div className='redes'>
                    <Link to='#'>
                        <img src={Facebook}/>
                    </Link>
                    <Link to='#'>
                        <img src={Instagram}/>
                    </Link>
                    <Link to='#'>
                        <img src={Twitter}/>
                    </Link>
                    <Link to='#'>
                        <img src={Youtube}/>
                    </Link>
                </div>
            </div>
            <div className='footer'>
                <div>
                    <div>Servicios</div>
                    <Link to='#'></Link>
                </div>
                <div>
                    <div>Social</div>
                    <Link to='#'>Facebook</Link>
                    <Link to='#'>Twitter</Link>
                    <Link to='#'>Instagram</Link>
                    <Link to='#'>Youtube</Link>
                </div>
                <div>
                    <div>Nosotros</div>
                    <Link to='#'>Conócenos</Link>
                    <Link to='#'>Misión y Visión</Link>
                    <Link to='#'>Contacto</Link>
                </div>
            </div>
            <div className='rights'>
                © Jhavac {new Date().getFullYear()}
            </div>
        </>
        
    )
}

export default Footer;