import { React, useState } from 'react';
import './Nosotros.css';
import { Link } from 'react-router-dom';

import Imagen0 from '../../img/Slider/Imagen0.png';
import Imagen1 from '../../img/Slider/Imagen1.png';
import Imagen2 from '../../img/Slider/Imagen2.png';
import Imagen3 from '../../img/Slider/Imagen3.png';

const Nosotros = ()=>{

    const [servicio, setServicio] = useState(0);

    return(
        <>
            <div className='section'>
                <h1>¿Quiénes somos?</h1>
                <h2>Nuestra prioridad es brindar soluciones integrales en logística y comercio internacional.</h2>
                <div className='desc'>
                    Somos una empresa especializada en la gestión de importaciones, exportaciones y logística integral. Ofrecemos servicios que abarcan desde la gestión de carga y aduanas hasta la búsqueda de proveedores y trámites de comercio exterior, asegurando eficiencia y cumplimiento en cada operación.
                </div>
                <div className='cuadros'>
                    <div>
                        <div className='divImg'>
                            <img src={Imagen1}/>
                        </div>
                        <h3>Trayectoria</h3>
                        <div>
                            Con años de experiencia en el mercado, hemos demostrado nuestro compromiso con la seguridad y la confianza de nuestros clientes.
                        </div>
                    </div>
                    <div>
                        <div className='divImg'>
                            <img src={Imagen2}/>
                        </div>
                        <h3>Personal Capacitado</h3>
                        <div>
                            La preparación constante es fundamental para mantenernos actualizados y en las mejores condiciones para ofrecer un servicio excepcional.
                            Nuestro equipo recibe formación continua para estar a la vanguardia en las últimas técnicas y procedimientos.
                        </div>
                    </div>
                    <div>
                        <div className='divImg'>
                            <img src={Imagen3}/>
                        </div>
                        <h3>Verdaders Expertos</h3>
                        <div>
                            En Jhavac, seguimos estándares internacionales rigurosos.
                            Nuestros procedimientos y lineamientos están diseñados para gestionar la seguridad de manera eficiente y efectiva.
                        </div>
                    </div>
                    <div>
                        <div className='divImg'>
                            <img src={Imagen3}/>
                        </div>
                        <h3>Tecnología de Vanguardia</h3>
                        <div>
                            Utilizamos métodos modernos y la más alta tecnología disponible en el sector.
                            Combinamos recursos humanos altamente calificados con herramientas avanzadas para garantizar la seguridad de nuestros clientes.
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export default Nosotros;